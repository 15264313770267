import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";

import httpConfig from "@/http/config.js";
import VConsole from 'vconsole';

// 测试环境出现
if(httpConfig.requestUrl === 'http://129.211.191.80:19003'){
  const vconsole = new VConsole()
  Vue.use(vconsole) 
}


// 配置UI组件库
import Vant from "vant";
import "vant/lib/index.css";
import { Toast, AddressEdit, Area, Dialog, Cascader, Popup } from "vant";
Vue.config.productionTip = false;

//引入自定义css
import "@/assets/css/common.css";

///打开APP权限配置
import wxconfig from "./config/wxconfig";
wxconfig();

///忽略使用组件报警告
Vue.config.ignoredElements = ["wx-open-launch-app"];

// Vue.use(Toast);
Vue.prototype.$toast = Toast; 
Vue.use(AddressEdit);
Vue.use(Area);
Vue.use(Dialog);
Vue.use(Cascader);
Vue.use(Popup);



// 全局混入
import "./utils/mixins";
Vue.use(Vant);

import jsCookie from "js-cookie";
Vue.prototype.$cookie = jsCookie;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

Vue.directive("title", {
  inserted: function (el) {
    document.title = el.dataset.title;
  },
});
