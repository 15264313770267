import Vue from "vue";
import urlencode from "urlencode";
import httpConfig from "@/http/config.js";
Vue.mixin({
  methods: {
    dealContent(content) {
      // console.log(content);
      if (
        content === undefined ||
        content === null ||
        content === "" ||
        content[0] !== "["
      ) {
        return "";
      }
      try {
        var text = JSON.parse(content);
        for (let item of text) {
          let type = Object.prototype.toString.call(item.insert);
          if (type === "[object String]") {
            return item.insert;
          }
        }
      } catch (e) {}
    },

    ///购买授权登录
    ///1.实体商品
    ///2.会员卡
    ///3.视频
    ///4.专栏
    ///5.邀请
    ///10. 邀请加入团队
    ///22. 优惠券
    buyAuthorizationFn(type, targetId) {
      console.log('执行了');
      let releaseUrl =  httpConfig.editUrl + "/login/buy?jumpType=" + type + "&&" + "targetId=" + targetId;

  

      releaseUrl = urlencode(releaseUrl);
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx650344a09eda8439&" +
        "&redirect_uri=" +
        releaseUrl +
        "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    },

    formattedText(itemInsertStr) {
      if (typeof itemInsertStr == "string") {
        return itemInsertStr.split("\n");
      }
      // console.log(itemInsertStr);
      // return itemInsertStr.split("\n");
      // // 将item.insert内的\n替换成<br>
      // var str = itemInsertStr.replace(/\n/g, "<br>");
      // console.log("输出内容：" + str);
      // return str;
    },
  },
});
