
const defaultFullData= {
  isManyAddress: false, // 是否存在多个地址
  itemIndex: null,// 满足的阶梯活动下标(阶梯满减才有值)
  shopInfo: [], // 所选商品信息(已勾选的商品信息)
  // 计算信息
  total: 0, // 总价 (商品总金额 原价*数量)
  mjyhAfter: 0, // 满减优惠后
  mjXs: 0, // 满减系数 即: (total-mjyhAfter)/ total
  couponyhAfter: 0, // (满减+优惠券)优惠后
  couponXs: 0, // 优惠券优惠系数 即: (mjyhAfter-couponyhAfter)/ mjyhAfter
  shopCouponInfo: {} // 使用的优惠券以及对应的商品信息
}
const state = {
    isFirst: 1, // 是否从满减活动 或者 商品详情进入的确认订单页面
    // 当前所选商品的相关信息  
    fullData: {
        isManyAddress: false, // 是否存在多个地址
        itemIndex: null,// 满足的阶梯活动下标(阶梯满减才有值)
        shopInfo: [], // 所选商品信息(已勾选的商品信息)
        // 计算信息
        total: 0, // 总价 (商品总金额 原价*数量)
        mjyhAfter: 0, // 满减优惠后
        mjXs: 0, // 满减系数 即: (total-mjyhAfter)/ total
        couponyhAfter: 0, // (满减+优惠券)优惠后
        couponXs: 0, // 优惠券优惠系数 即: (mjyhAfter-couponyhAfter)/ mjyhAfter
        shopCouponInfo: {} // 使用的优惠券以及对应的商品信息
    },
    // 当前满减活动信息
    maxOutDetails: {},
    allShopList: [], // 单个满减 购物车数据
}

const mutations = {
  // 存储所勾选商品信息
  SET_IS_DATA: (state, data) => {
    state.isFirst= data
  },
  // 存储所勾选商品信息
  SET_AREA_DATA: (state, data) => {
    if(data!=='reset'){
      state.fullData = { ...{},...state.fullData, ...data }
    }else{
      state.maxOutDetails={};
      state.allShopList=[];
      state.fullData = defaultFullData
    }
    console.log( '更新',state.fullData );
  },
  SET_ACTIVITY_DATA: (state, data)=>{
    state.maxOutDetails = data
    console.log( '活动信息存储',state.maxOutDetails );
  },
  SET_ALLSHOP_DATA: (state, data)=>{
    state.allShopList = data
    console.log( '已添加的所有商品',state.allShopList );
  }
}

const actions = {

//   async getAreaData({ commit }) {
    // const res = await getArea()
    // const { code, name, children } = res.data || {}
    // commit('SET_AREA_DATA', children)
    // const areaWithCountry = [
    //   { code, name, children }
    // ]
    // commit('SET_AREA_COUNTRY_DATA', areaWithCountry)	
//   }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}