import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

//添加以下代码解决 Vue 路由传递参数时，出现 Uncaught (in promise) NavigationDuplicated: Avoided redundant navigation 问题 .
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  // 话题详情
  {
    path: "/topic",
    name: "Topic",
    component: (resolve) => require(["../views/topic/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "话题详情",
    },
  },
  // 图文详情
  {
    path: "/imageText",
    name: "ImageText",
    component: (resolve) => require(["../views/imageText/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "图文详情",
    },
  },
  // 直播分享
  {
    path: "/watchLive",
    name: "WatchLive",
    component: (resolve) => require(["../views/watchLive/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "直播分享",
    },
  },
  // 想法
  {
    path: "/idea",
    name: "Idea",
    component: (resolve) => require(["../views/idea/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "想法详情",
    },
  },
  // 文章
  {
    path: "/article",
    name: "Article",
    component: (resolve) => require(["../views/article/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "文章详情",
    },
  },
  // 会员充值页
  {
    path: "/vip",
    name: "Vip",
    component: (resolve) => require(["../views/vip/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "VIP会员",
    },
  },

  //TODO. 每周精选
  {
    path: "/weekSelect",
    name: "WeekSelect",
    component: (resolve) => require(["../views/weekSelect/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "每周精选",
    },
  },
  // 每周精选详情
  {
    path: "/weekSelect/details",
    name: "WeekSelectDetails",
    component: (resolve) =>
      require(["../views/weekSelect/Details.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "精选详情",
    },
  },
  // 登陆注册
  {
    path: "/login",
    name: "index",
    component: (resolve) => require(["../views/login/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "九典",
    },
  },

  {
    path: "/login/login",
    name: "Login",
    component: (resolve) => require(["../views/login/Login.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "九典",
    },
  },

  ///所有商品购买的登录,使用这个授权
  {
    path: "/login/buy",
    name: "buyLogin",
    component: (resolve) => require(["../views/login/BuyLogin.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "九典",
    },
  },

  {
    path: "/partner",
    name: "Partner",
    component: (resolve) => require(["../views/partner/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "创业星球招募",
    },
  },
  // 合伙人申请
  // {
  //   path: "/apply",
  //   name: "Partner",
  //   component: (resolve) => require(["../views/partner/Apply.vue"], resolve),
  //   meta: {
  //     keepAlive: false, // 不需要缓存
  //     title: "创业星球招募",
  //   },
  // },
  // 合伙人邀请
  {
    path: "/partner/invite",
    name: "InvitePartner",
    component: (resolve) => require(["@/views/partner/Invite.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "创业星球招募",
    },
  },

  // 邀请购买会员卡
  {
    path: "/invite/member",
    name: "InviteMember",
    component: (resolve) => require(["@/views/member/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "九典会员",
    },
  },

  // 会员卡页面
  {
    path: "/buyMember",
    name: "BuyMember",
    component: (resolve) => require(["@/views/member/IndexBuy.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "开通会员",
    },
  },

  // 邀请有礼
  {
    path: "/invite/invite",
    name: "InviteUser",
    component: (resolve) => require(["@/views/invite/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "邀请有礼",
    },
  },
  // 邀请成功
  {
    path: "/success",
    name: "success",
    component: (resolve) => require(["@/views/partner/Success.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "创业星球招募",
    },
  },
  // 视频详情
  {
    path: "/video",
    name: "Video",
    component: (resolve) => require(["../views/video/index"], resolve),
    meta: {
      // 不需要缓存
      keepAlive: false,
      title: "视频详情",
    },
  },

  // 专栏
  {
    path: "/column",
    name: "Column",
    component: (resolve) => require(["../views/column/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "专栏详情",
    },
  },

  // 视频合集
  {
    path: "/videoCollection",
    name: "VideoCollection",
    component: (resolve) =>
      require(["../views/videoCollection/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "视频合集",
      // header: "hide",
      // footer: "hide",
    },
  },

  // 书房招商加盟活动海报
  {
    path: "/joinStudyActivity",
    name: "JoinStudyActivity",
    component: (resolve) =>
      require(["../views/joinStudyActivity/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "书房-招商加盟",
      // header: "hide",
      // footer: "hide",
    },
  },
  {
    path: "/joinStudyApply",
    name: "JoinStudyApply",
    component: (resolve) =>
      require(["../views/joinStudyActivity/Apply.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "书房-申请",
      // header: "hide",
      // footer: "hide",
    },
  },
  {
    path: "/joinStudyApplyRecord",
    name: "JoinStudyApplyRecord",
    component: (resolve) =>
      require(["../views/joinStudyActivity/Record.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "书房-申请记录",
      // header: "hide",
      // footer: "hide",
    },
  },

  // 商城营销活动
  {
    path: "/mallActivity",
    name: "MallActivity",
    component: (resolve) =>
      require(["../views/mallActivity/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "活动页面",
      // header: "hide",
      // footer: "hide",
    },
  },

  // 问答
  {
    path: "/issueAndAnswer",
    name: "IssueAndAnswer",
    component: (resolve) =>
      require(["../views/issueAndAnswer/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "问答详情",
    },
  },

  //问题回答分享
  {
    path: "/issueAndAnswer/answerDetails",
    name: "AnswerDetails",
    component: (resolve) =>
      require(["../views/issueAndAnswer/issueAnswerDetails"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "回答详情",
    },
  },

  // 商品评价
  {
    path: "/goodsReview",
    name: "GoodsReview",
    component: (resolve) =>
      require(["../views/goodsReview/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "商品评价",
    },
  },

  // 商品详情
  {
    path: "/goods",
    name: "Goods",
    component: (resolve) => require(["../views/goods/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "商品详情",
    },
  },
  {
    path: "/goods/evaluate",
    name: "evaluate",
    component: (resolve) => require(["../views/goods/evaluate.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "商品评价",
    },
  },

  // 确认订单
  {
    path: "/goodsPay",
    name: "GoodsPay",
    component: (resolve) => require(["../views/goodsPay/Index.vue"], resolve),
    meta: {
      keepAlive: false,
      title: "确认支付",
    },
  },
  {
    path: "/manyOrderPay",
    name: "ManyOrderPay",
    component: (resolve) => require(["../views/goodsPay/manyOrderPay.vue"], resolve),
    meta: {
      keepAlive: true,
      title: "确认订单",
    },
  },

  // 地址信息
  {
    path: "/goodsReAddress",
    name: "goodsReAddress",
    component: (resolve) =>
      require(["../views/goodsReAddress/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "我的收货地址",
    },
  },

  // 新增地址
  {
    path: "/goodsAddAddress",
    name: "goodsAddAddress",
    component: (resolve) =>
      require(["../views/goodsAddAddress/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "新增地址",
    },
  },

  {
    path: "/goodsEditAddress",
    name: "goodsEditAddress",
    component: (resolve) =>
      require(["../views/goodsEditAddress/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "编辑地址",
    },
  },

  //拼团商品分享微信落地页
  {
    path: "/goodsShare",
    name: "GoodsShare",
    component: (resolve) => require(["../views/goodsShare/Index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "参与拼团",
    },
  },

  // 用户协议
  {
    path: "/agreement/user",
    name: "User",
    component: (resolve) => require(["@/views/agreement/User.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "用户协议",
    },
  },

  // 隐私协议
  {
    path: "/agreement/init",
    name: "Init",
    component: (resolve) => require(["@/views/agreement/Init.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "隐私协议",
    },
  },

  // 会员协议
  {
    path: "/agreement/member",
    name: "AgreementMember",
    component: (resolve) => require(["@/views/agreement/Member.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "会员协议",
    },
  },

  // 创业星球如何赚钱
  {
    path: "/agreement/starMoney",
    name: "starMoney",
    component: (resolve) =>
      require(["@/views/agreement/starMoney.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "创业星球如何赚钱",
    },
  },
  // 创业星球规则
  {
    path: "/agreement/starRules",
    name: "starRules",
    component: (resolve) =>
      require(["@/views/agreement/starRules.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "创业星球规则",
    },
  },
  // 创业星球权益
  {
    path: "/agreement/starEquity",
    name: "starEquity",
    component: (resolve) =>
      require(["@/views/agreement/starEquity.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "创业星球权益",
    },
  },
  // 常见问题
  {
    path: "/agreement/starIntroduce",
    name: "starIntroduce",
    component: (resolve) =>
      require(["@/views/agreement/StarIntroduce.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "创业星球常见问题",
    },
  },
  ///测试专用页面
  {
    path: "/test",
    name: "test",
    component: (resolve) => require(["@/views//testPage/index.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "测试专用",
    },
  },

  ///组件封装页面
  {
    path: "/testb",
    name: "testb",
    component: (resolve) =>
      require(["@/views//testPage/OpenTest.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "测试专用",
    },
  },

  // 结果页
  {
    path: "/payFail",
    name: "PayFail",
    component: (resolve) => require(["@/views/resPage/PayFail.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "支付结果",
    },
  },
  {
    path: "/paySuccess",
    name: "PaySuccess",
    component: (resolve) =>
      require(["@/views/resPage/PaySuccess.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "支付结果",
    },
  },
  // 拼团中
  {
    path: "/inGroup",
    name: "InGroup",
    component: (resolve) => require(["@/views/resPage/InGroup.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "支付结果",
    },
  },
  // 拼团失败
  {
    path: "/groupFail",
    name: "GroupFail",
    component: (resolve) => require(["@/views/resPage/GroupFail.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "支付结果",
    },
  },
  // 拼团成功
  {
    path: "/groupSuccess",
    name: "GroupSuccess",
    component: (resolve) =>
      require(["@/views/resPage/GroupSuccess.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "支付结果",
    },
  },
  {
    path: "/download",
    name: "download",
    component: (resolve) => require(["@/views/Download.vue"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "首页",
    },
  },
  {
    path: "/goodsConfirmPay",
    name: "goodsConfirmPay",
    component: (resolve) => require(["@/views/goodsConfirmPay/Index"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "确认支付",
    },
  },
  // 禁用模块
  {
    path: "/ban",
    name: "ban",
    component: (resolve) => require(["@/views/ban/Login"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "登陆",
    },
  },
  {
    path: "/ban/index",
    name: "ban",
    component: (resolve) => require(["@/views/ban/Index"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "禁用",
    },
  },

  //书房
  {
    path: "/baike",
    name: "BaiKe",
    component: (resolve) =>
      require(["@/views/bookRoom/note/baike/Index"], resolve),
    meta: {
      keepAlive: true, // 不需要缓存
      title: "百科笔记",
    },
  },

  {
    path: "/dianzishu",
    name: "DianZiShu",
    component: (resolve) =>
      require(["@/views/bookRoom/note/dianzishu/Index"], resolve),
    meta: {
      keepAlive: true, // 不需要缓存
      title: "电子书笔记",
    },
  },

  {
    path: "/huodong",
    name: "HuoDong",
    component: (resolve) =>
      require(["@/views/bookRoom/note/huodong/Index"], resolve),
  },

  {
    path: "/gushici",
    name: "GuShiCi",
    component: (resolve) =>
      require(["@/views/bookRoom/note/gushici/Index"], resolve),
  },

  ///优惠券
  {
    path: "/coupon",
    name: "CouponPage",
    component: (resolve) => require(["@/views/coupon/Index"], resolve),
    meta: {
      keepAlive: false, // 不需要缓存
      title: "九典商城优惠券",
    },
  },
  {
    path: "/fullReduction",
    name: "FullReduction",
    component: (resolve) => require(["@/views/fullReduction/fullReduction"], resolve),
    meta: {
      keepAlive: false, // 需要缓存
      title: "满减活动",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//2.使用router.beforeEach对路由进行遍历，设置title
router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title;
  } else {
    document.title = "九典";
  }
  next();
});
export default router;
